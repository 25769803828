import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import {
  Title,
  Container,
  Button,
  Pagination,
  BlackTitle,
  ResultCard
  // BlurryCard
} from '../ui-components'
import { Loading } from './components/Loading'
import { InfoModal } from './components'
import styled from 'styled-components/macro'
// import title from '../../assets/bootcamp/blurryTitle-min.png'
import success from '../../assets/bootcamp/success.svg'
import checkIcon from '../../assets/landing/check.svg'
// import { bootcamps } from './fixtureData'
import { color, fonts, breakpoints } from '../../theme'

const emailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
)

const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.innential.com/micro-site/bootcamp'
    : 'https://api-staging.innential.com/micro-site/bootcamp'
// const scrollToRef = ref =>
// window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })

const StyledSection = styled.section`
  display: flex;
  /* margin: 50px 0 0; */
  justify-content: center;
  min-height: 500px;
  @media ${breakpoints('lg')} {
    justify-content: space-between;
    margin: 80 0 ${props => (props.top ? '3%' : '30px')};
  }
  div.textured {
    width: 50%;
  }
`

// const StickyFooter = styled.div`
//   background-color: white;
//   padding: 20px;
//   text-align: center;
//   z-index: 100;
//   box-shadow: 0 -6px 4px -4px rgba(0, 0, 0, 0.03);
//   position: fixed;
//   width: 100%;
//   top: auto;
//   bottom: 0;
//   right: 0;
// `

// const BigButton = styled.button`
//   line-height: 1.63;
//   border-radius: 100px;
//   background-color: ${color('purple')};
//   border: none;
//   outline: none;
//   color: white;
//   font-family: ${fonts('primaryBold')};
//   font-size: 16px;
//   cursor: pointer;
//   padding: 12px 38px;

//   :hover {
//     opacity: 0.7;
//   }
// `

// const ChartContainer = styled.div`
//   display: none;
//   position: absolute;
//   left: 50%;
//   background-color: white;
//   height: 451px;
//   width: 460px;
//   margin: 0 auto;
//   border-radius: 6px;
//   box-shadow: 0 16px 32px 0 rgba(0, 8, 32, 0.1), 0 1px 4px 0 rgba(0, 8, 32, 0.1);
//   @media ${breakpoints('lg')} {
//     display: unset;
//   }
// `

const Career = styled.div`
  min-height: 228px;
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakpoints('md')} {
    margin: 0;
    align-items: flex-start;
  }
  @media ${breakpoints('lg')} {
    max-width: 680px;
  }
  h1 {
    text-align: center;
    font-family: ${fonts('primaryBlack')};
    margin: 0;
    @media ${breakpoints('md')} {
      text-align: start;
    }
  }
  p {
    font-family: ${fonts('primaryRegular')};
    font-size: 16px;
    line-height: 30px;
    color: ${color('black')};
  }

  p.ptSans {
    font-family:"PT Sans";
  }
  /* p.path {
    font-size: 14px;
    font-family: ${fonts('primaryBold')};
    font-weight: 700;
    color: ${color('purple')};
    line-height: 1.57;
    letter-spacing: 0.11px;
    align-self: start;
  } */
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 14px 0 0;
  input {
    margin: 0 0 24px 0;
    border: solid 1px ${color('purple')};
    min-height: 2rem;
  }
  @media ${breakpoints('md')} {
    flex-direction: row;
    input {
      margin: 0 -23px 0 0;
      width: 400px;
      font-family: ${fonts('primaryRegular')};
      color: ${color('darkyBlue')};
    }
  }
`
const StyledSmall = styled.small`
  font-family: ${fonts('secondary')};
  font-size: 18px;
  margin-left: 12px;
  color: ${props => (props.green ? color('green') : color('black'))};
`
// const CoursesColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 50%;
//   margin: 0 32px 0 0;

//   h3 {
//     font-family: ${fonts('primaryBlack')};
//     font-size: 24px;
//     line-height: 1.58;
//     color: ${color('black')};
//   }
// `

// const StyledResultContainer = styled.div`
//   max-width: 1098px;
//   margin: auto;
//   div.textured {
//     display: none;
//     position: absolute;
//     top: 0;
//     right: 0;
//     height: 500px;
//     width: 600px;
//     /* background-image:  */
//     opacity: 1;
//     @media ${breakpoints('lg')} {
//       display: block;
//     }
//   }

//   div.transparent {
//     opacity: 1;
//     transition: opacity 0.3s ease-in-out;
//   }
// `
// const InfoText = styled.p`
//   font-family: ${fonts('secondary')};
//   margin-top: ${props => (props.noshow ? '42px' : 'unset')};
//   font-style: ${props => (props.learning ? 'italic' : 'unset')};
//   color: ${props =>
//     props.learning ? 'lightgray' : props.noshow ? color('grey') : 'inherit'};
//   font-size: 14px;
//   font-weight: ${props => (props.bold ? '700' : '400')};
//   text-align: ${props => (props.learning ? 'unset' : 'center')};
//   width: ${props => (props.show ? 'unset' : 'max-content')};
//   cursor: ${props => (props.learning || props.noshow ? 'inherit' : 'pointer')};
// `

const HeroImage = styled.img`
  display: none;
  transition: translate 0.3s ease-in-out;
  @media ${breakpoints('md')} {
    display: flex;
    width: 340px;
    transform: translateY(-70px);
  }
  @media ${breakpoints('lg')} {
    min-height: 200px;
    width: unset;
    transform: translateY(0px);
  }
`

const CheckboxContainer = styled.label`
  input {
    cursor: pointer;
  }
`

const environment =
  process.env.NODE_ENV === 'production' ? 'production' : 'local'

const Result = ({
  match: {
    params: { resultId }
  },
  location: { search }
}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [validated, setValidated] = useState(true)
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // const [modalTitle, setModalTitle] = useState('')
  // const [modalMessage, setModalMessage] = useState('')

  const searchParams = new URLSearchParams(search)

  const verificationKey = searchParams.get('verify')

  useEffect(() => {
    setLoading(true)

    axios
      .post(
        `${apiUrl}/results`,
        JSON.stringify({
          resultId,
          ...(verificationKey && { key: verificationKey })
        }),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.error(err)
        setError(err)
      })
      .finally(() => setLoading(false))

    if (verificationKey)
      window.history.replaceState({}, document.title, window.location.pathname)
  }, [resultId, verificationKey])

  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  if (error) {
    return (
      <Container>
        <Career>
          <Title>Something went wrong.</Title>
          <p>
            Either the result could not be found, or there's a problem with our
            servers. Please try again at a later time.
          </p>
        </Career>
        <Link to="/">
          <p>Home</p>
        </Link>
      </Container>
      // <InfoModal
      //   isOpen={modalIsOpen}
      //   setIsOpen={setModalIsOpen}
      //   modalTitle="Received!"
      //   // {modalTitle}
      //   modalMessage="Check your email for more information."
      //   // {modalMessage}
      //   setModalTitle={setModalTitle}
      //   setModalMessage={setModalMessage}
      // />
    )
  }

  if (data) {
    const { onsiteCourses, remoteCourses } = data.bootcampData.reduce(
      (acc, curr) => {
        return {
          ...acc,
          ...(curr.locations.indexOf(data.location) !== -1 && {
            onsiteCourses: [...acc.onsiteCourses, curr]
          }),
          ...(curr.remote && {
            remoteCourses: [...acc.remoteCourses, curr]
          })
        }
      },
      { onsiteCourses: [], remoteCourses: [] }
    )

    // const setModalInfo = (title, content, open) => {
    //   setModalTitle(title)
    //   setModalMessage(content)
    //   setModalIsOpen(open)
    // }

    return (
      <Container>
        {/* <div className={loading ? 'transparent' : 'textured'}></div> */}
        {data && (
          <div>
            <StyledSection top>
              <Career>
                {/* <h1>{data.roleChosen}</h1> */}
                <Title>
                  {data.verified
                    ? `Thank you! You can see your matches below.`
                    : data.nBootcamps > 0
                    ? `We found ${data.nBootcamps} bootcamp${
                        data.nBootcamps > 1 ? 's' : ''
                      } and ${data.nOptions} funding option${
                        data.nOptions > 1 ? 's' : ''
                      } for you.`
                    : `Unfortunately, we couldn't find a bootcamp that matches your needs`}
                </Title>
                {!data.verified && !data.email ? (
                  <>
                    <p>
                      {data.nBootcamps > 0
                        ? `Enter your email to see your results.`
                        : `Leave your email and we’ll write when we find a course that fits.`}
                    </p>
                    <InputContainer>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                      <Button
                        onClick={async () => {
                          const valid = emailRegex.test(email)
                          if (valid) {
                            try {
                              await axios.post(
                                `${apiUrl}/email`,
                                JSON.stringify({
                                  resultId,
                                  email,
                                  contact,
                                  environment
                                }),
                                {
                                  headers: {
                                    'Content-Type': 'application/json'
                                  }
                                }
                              )
                              setEmailSent(true)
                              setModalIsOpen(true)
                            } catch (err) {
                              console.error(err)
                              setError(err)
                            }
                          }
                        }}
                        cta="Submit"
                        disabled={
                          email.length === 0 ||
                          !emailRegex.test(email) ||
                          emailSent
                        }
                      />
                    </InputContainer>
                    {data.nBootcamps > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            margin: '12px 0 0',
                            alignItems: 'center'
                          }}
                        >
                          {emailSent ? (
                            <img src={checkIcon} alt="verified" />
                          ) : (
                            <CheckboxContainer>
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={contact}
                                onChange={e => setContact(e.target.checked)}
                              />
                            </CheckboxContainer>
                          )}
                          <StyledSmall green={emailSent}>
                            {emailSent
                              ? 'Received! Check your email for more information.'
                              : `Put me in touch with my matched bootcamps to learn more. I agree that my data will be shared with only the matched bootcamps.`}
                          </StyledSmall>
                        </div>
                        {!emailSent && (
                          <div
                            style={{
                              padding: '35px 0 0 32px',
                              fontFamily: 'PT Sans',
                              fontSize: '18px',
                              color: '#152540'
                            }}
                          >
                            <strong>
                              We strongly recommend checking this!
                            </strong>
                            <br />
                            <br />
                            People who did had (1) a faster response time from
                            bootcamps and (2) a better chance to be accepted
                            into a program
                          </div>
                        )}
                      </>
                    ) : (
                      emailSent && (
                        <div
                          style={{
                            display: 'flex',
                            margin: '12px 0 0',
                            alignItems: 'center'
                          }}
                        >
                          <img src={checkIcon} alt="verified" />
                          <StyledSmall green>
                            Received! We'll get in touch once we've found
                            something for you.
                          </StyledSmall>
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <div>
                    {data.verified ? (
                      <p className="ptSans">
                        You don't have to do anything else.
                        <br />
                        <br />
                        We'll introduce you to the bootcamps below.
                        <br />
                        <br />
                        <strong>
                          All financing below comes with the course it's
                          attached to.
                        </strong>{' '}
                        You can only get financing if you are accepted into one
                        of the courses.
                      </p>
                    ) : (
                      <p>
                        We've sent the results to <strong>{data.email}</strong>.
                        Please check your inbox for more information.
                      </p>
                    )}
                  </div>
                )}
              </Career>
              <HeroImage src={success} alt="bootcamps found!" />
              {/* {data.chartData && (
                  <ChartContainer>
                    <SGChart chartData={data.chartData} isLanding={false} />
                  </ChartContainer>
                )} */}
            </StyledSection>
            {data.nBootcamps > 0 && (
              <>
                {data.remote !== 'Remote' && (
                  <>
                    <div className="title-container">
                      {data.verified && (
                        <BlackTitle>
                          Bootcamps in{' '}
                          <span style={{ color: '#5a55ab' }}>
                            {data.location}
                          </span>
                        </BlackTitle>
                      )}
                    </div>
                    <div>
                      {onsiteCourses.length > 0
                        ? onsiteCourses.map((bootcamp, idx) => (
                            <ResultCard
                              key={`bootcamp:${bootcamp.bootcamp}:${idx}`}
                              idx={idx}
                              bootcamp={bootcamp}
                            />
                          ))
                        : data.verified && (
                            <p style={{ fontFamily: 'PT Sans' }}>
                              Unfortunately no on-site courses are available at
                              your location at this time.
                            </p>
                          )}
                    </div>
                  </>
                )}
                {data.verified &&
                  (
                    <>
                      <div className="title-container">
                        <BlackTitle>Remote courses</BlackTitle>
                      </div>
                      <div>
                        {remoteCourses.filter(({ bootcamp }) => !onsiteCourses.some(({ bootcamp: onsite }) => onsite === bootcamp)).map((bootcamp, idx) => (
                          <ResultCard
                            key={`bootcamp:${bootcamp.bootcamp}:${idx}`}
                            idx={idx}
                            bootcamp={bootcamp}
                          />
                        ))}
                      </div>
                    </>
                  )}
              </>
            )}
            <Pagination active="Results" done={data.verified} />
            {/* {savedContent ? (
                <ShowLearningPath content={savedContent} />
              ) : (
                <ListResults {...data} anchor={anchor} saveResult={saveResult} />
              )} */}
          </div>
        )}
        {!data.verified && (
          <InfoModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            // modalTitle={modalTitle}
            // modalMessage={modalMessage}
            // setModalTitle={setModalTitle}
            // setModalMessage={setModalMessage}
          />
        )}
      </Container>
    )
  }
  return null
}

export default withRouter(Result)

// const ListResults = React.memo(
//   ({
//     recommendedCourses,
//     recommendedBites,
//     otherBites,
//     otherCourses,
//     hours,
//     anchor,
//     saveResult
//   }) => {
//     const [showMoreCourses, setShowMoreCourses] = useState(false)
//     const [showMoreFreeContent, setShowMoreFreeContent] = useState(false)
//     const [recommendations, setRecommendations] = useState([])
//     const [recommendationsNullCount, setRecommendationsNullCount] = useState(0)
//     const [displayRecommendations, setDisplayRecommendations] = useState(true)
//     const [moreCourses, setMoreCourses] = useState([])
//     const [biteCourses, setBiteCourses] = useState([])
//     const [bitesNullCount, setBitesNullCount] = useState(0)
//     const [displayBites, setDisplayBites] = useState(true)
//     const [moreBites, setMoreBites] = useState([])
//     const [userCourses, setUserCourses] = useState([])
//     // const [filterActive, setFilterActive] = useState(false)
//     const [filters, setFilters] = useState({ price: 'free', time: hours })

//     const { filterActive, setFilterActive, filteredResults } = useFilter(hours)

//     const { generateNodes, LearningItemsNodes } = useResult()

//     useEffect(() => {
//       if (recommendedCourses.length > 0) {
//         const withExtrafFields = addSelectedField(
//           recommendedCourses,
//           'recommended'
//         )
//         setRecommendations(withExtrafFields)
//       }
//     }, [recommendedCourses])

//     useEffect(() => {
//       if (otherCourses.length > 0) {
//         const withExtrafFields = addSelectedField(otherCourses, 'other')
//         setMoreCourses(withExtrafFields)
//       }
//     }, [otherCourses])

//     useEffect(() => {
//       if (recommendedBites.length > 0) {
//         const withExtrafFields = addSelectedField(recommendedBites, 'bites')
//         setBiteCourses(withExtrafFields)
//       }
//     }, [recommendedBites])

//     useEffect(() => {
//       if (otherBites.length > 0) {
//         setMoreBites(addSelectedField(otherBites, 'otherBites'))
//       }
//     }, [otherBites])

//     useEffect(() => {
//       const userCoursesArr = recommendations
//         .concat(moreCourses)
//         .concat(biteCourses)
//         .concat(moreBites)
//         .filter(course => course.selected)
//       setUserCourses(userCoursesArr)
//     }, [recommendations, moreCourses, biteCourses, moreBites])

//     const nullReducer = (acc, item) => (item === null ? acc + 1 : acc)

// const filteredResults = (arr, filter) =>
//   filter.price === 'free'
//     ? arr.filter(({ duration, price }) =>
//         duration !== null && duration !== undefined
//           ? duration.hours
//             ? (price.value === 0 &&
//                 duration.hours <= parseInt(filter.time)) ||
//               (price.value === 0 &&
//                 duration.hours === filter.time &&
//                 duration.minutes === 0)
//             : price.value === 0 &&
//               duration.hoursMin <= parseInt(filter.time)
//           : price.value === 0
//       )
//     : arr.filter(({ duration, price }) =>
//         duration !== null && duration !== undefined
//           ? duration.hours
//             ? (price.value > 0 && duration.hours < parseInt(filter.time)) ||
//               (price.value > 0 &&
//                 duration.hours === filter.time &&
//                 duration.minutes === 0)
//             : price.value > 0 && duration.hoursMin <= parseInt(filter.time)
//           : price.value > 0
//       )

// const filteredRecommendations = filterActive
//   ? filteredResults(recommendations, filters)
//   : recommendations
// const filteredMoreCourses = filterActive
//   ? filteredResults(moreCourses, filters)
//   : moreCourses

// const recommendationsNodes = useMemo(
//   () =>
//     generateNodes(
//       filteredRecommendations,
//       setRecommendations,
//       recommendations,
//       LearningItemsNodes
//     ),
//   [
//     filteredRecommendations,
//     recommendations,
//     LearningItemsNodes,
//     generateNodes
//   ]
// )

// const moreCoursesNodes = useMemo(
//   () =>
//     generateNodes(
//       filteredMoreCourses,
//       setMoreCourses,
//       moreCourses,
//       LearningItemsNodes
//     ),
//   [filteredMoreCourses, moreCourses, LearningItemsNodes, generateNodes]
// )

// const biteCoursesNodes = generateNodes(
//   biteCourses,
//   setBiteCourses,
//   biteCourses,
//   LearningItemsNodes
// )

// useEffect(() => {
//   setRecommendationsNullCount(recommendationsNodes.reduce(nullReducer, 0))
//   setDisplayRecommendations(
//     recommendationsNodes.length > recommendationsNullCount
//   )
// }, [recommendationsNodes, recommendationsNullCount, displayRecommendations])

// useEffect(() => {
//   setBitesNullCount(biteCoursesNodes.reduce(nullReducer, 0))
//   setDisplayBites(biteCoursesNodes.length > bitesNullCount)
// }, [bitesNullCount, biteCoursesNodes])

// useEffect(() => {
//   if (
//     recommendationsNodes.length > 0 &&
//     recommendationsNodes.length === recommendationsNullCount &&
//     moreCoursesNodes.length > 0 &&
//     !showMoreCourses
//   ) {
//     setShowMoreCourses(true)
//   }
// }, [
//   recommendationsNullCount,
//   recommendationsNodes,
//   showMoreCourses,
//   moreCoursesNodes
// ])

// useEffect(() => {
//   if (
//     biteCoursesNodes.length > 0 &&
//     biteCoursesNodes.length === bitesNullCount &&
//     !showMoreFreeContent
//   ) {
//     setShowMoreFreeContent(true)
//   }
// }, [bitesNullCount, biteCoursesNodes, showMoreFreeContent])

// const addSelectedField = (courseAray, courseType) =>
//   courseAray.map(course => ({
//     ...course,
//     selected: false,
//     courseType
//   }))

// const getOriginArray = courseType => {
//   switch (courseType) {
//     case 'recommended':
//       return [recommendations, setRecommendations]
//     case 'other':
//       return [moreCourses, setMoreCourses]
//     case 'bites':
//       return [biteCourses, setBiteCourses]
//     case 'otherBites':
//       return [moreBites, setMoreBites]
//     default:
//       return null
//   }
// }

// const filteredBitesCourses = filteredResults(biteCourses, filters)
// const filteredMoreBites = filteredResults(moreBites, filters)

//     const moreBitesNodes = generateNodes(
//       moreBites,
//       setMoreBites,
//       moreBites,
//       LearningItemsNodes
//     )

//     const moreBitesNullCount = moreBitesNodes.reduce(nullReducer, 0)
//     const moreCoursesNullCount = moreCoursesNodes.reduce(nullReducer, 0)

//     const showMoreText = `Show ${showMoreCourses ? 'less' : 'more'}`
//     const showMoreBitesText = `Show ${showMoreFreeContent ? 'less' : 'more'}`
//     const noMoreShowText =
//       'No further recommendations to show.. Try adjusting your filters!'
//     return (
//       <>
//         <div ref={anchor}></div>
//         <Filter
//           filters={filters}
//           setFilters={setFilters}
//           filterActive={filterActive}
//           setFilterActive={setFilterActive}
//         />
//         <StyledSection>
//           <CoursesColumn>
//             <h3>Your Learning Path</h3>
//             {userCourses.length > 0 ? (
//               <UserCourses
//                 userCourses={userCourses}
//                 getOriginArray={getOriginArray}
//               />
//             ) : (
//               <EmptyItem />
//             )}
//           </CoursesColumn>
//           <CoursesColumn>
//             {displayRecommendations ||
//             (moreCoursesNodes.length > moreCoursesNullCount &&
//               showMoreCourses) ? (
//               <h3>Recommendations</h3>
//             ) : null}
//             {displayRecommendations ? (
//               <>{recommendationsNodes}</>
//             ) : (
//               !showMoreCourses && <InfoText noshow>{noMoreShowText}</InfoText>
//             )}

//             {showMoreCourses && moreCoursesNodes}
//             {moreCoursesNodes.length > 0 && displayRecommendations && (
//               <h4
//                 onClick={() =>
//                   setShowMoreCourses(showMoreCourses => !showMoreCourses)
//                 }
//               >
//                 <InfoText bold show>
//                   {showMoreText}
//                 </InfoText>
//               </h4>
//             )}

//             {(displayBites && !showMoreFreeContent) ||
//             (moreBitesNodes.length > moreBitesNullCount &&
//               showMoreFreeContent) ? (
//               <h3>Learning bites</h3>
//             ) : null}
//             {biteCoursesNodes}

//             {showMoreFreeContent && moreBitesNodes}
//             {moreBitesNodes.length > 0 && displayBites && (
//               <h4
//                 onClick={() =>
//                   setShowMoreFreeContent(
//                     showMoreFreeContent => !showMoreFreeContent
//                   )
//                 }
//               >
//                 <InfoText bold show>
//                   {' '}
//                   {moreBitesNodes.length > moreBitesNullCount
//                     ? showMoreBitesText
//                     : null}
//                 </InfoText>
//               </h4>
//             )}
//           </CoursesColumn>
//         </StyledSection>
//         {userCourses.length > 0 && (
//           <StickyFooter>
//             <BigButton onClick={() => saveResult(userCourses)}>
//               Start your Path
//             </BigButton>
//           </StickyFooter>
//         )}
//       </>
//     )
//   }
// )

// const ShowLearningPath = ({ content }) => {
//   console.log(content)

//   return (
//     <>
//       <h3>Your Learning Path</h3>
//       {content.map(item => (
//         <LearningItem key={item._id} {...item} saved />
//       ))}
//     </>
//   )
// }
