import React from 'react'
import styled from 'styled-components'

const StyledArrow = styled.svg`
  stroke: #5a55ab;
  stroke-width: 2px;
  cursor: pointer;
  fill: none;
  margin-left: 6px;
  height: ${props => (props.down ? '8px' : '7px')};
  width: ${props => (props.down ? '14px' : '10px')};
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.down ? 'none' : 'rotate(-90deg)')};
  /* &:hover {
    transform: translateY(2%) scale(1.1);
  } */
`

const ArrowIcon = ({ scroll, down }) => {
  return (
    <StyledArrow down={down} viewBox="0 0 13.2 6.61">
      <path d="m0.0166 0.333 6.6 5.97 6.58-5.99" />
    </StyledArrow>
  )
}

export default ArrowIcon
