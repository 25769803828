import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'

const StyledContainer = styled.div`
  padding-top: 5%;
  width: 100%;

  @media screen and (min-width: 800px) {
    margin: ${props => (props.match ? '0 auto' : 0)};
  }

  div.title-container {
    display: flex;
    justify-content: center;
    margin: 24px 0;

    @media ${breakpoints('md')} {
      justify-content: flex-start;
    }
    @media ${breakpoints('lg')} {
      margin: 0 0 24px;
    }
  }
`

const Container = ({ children, match }) => (
  <StyledContainer match={match}>{children}</StyledContainer>
)

export default Container
