import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 18px;
  padding: 13px 56px;
  background-color: #5a55ab;
  font-family: 'Poppins Bold';
  font-weight: 700;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.13px;
  width: ${props => (props.match ? 'unset' : ' 171px')}
  height: 50px;
  white-space: nowrap;
  border-radius: 100px;
  align-self: center;

  :disabled {
    background-color: #8a88ab;
    cursor: not-allowed;
  }

  @media screen and (min-width: 892px) {
    align-self: start !important;
  }

  span {
    color: #ffffff;
    text-decoration: none;
  }
`

const Button = ({ cta, onClick, match, disabled }) => (
  <StyledButton
    match={match}
    onClick={e => {
      if (typeof onClick === 'function') {
        e.preventDefault()
        onClick(e)
      }
    }}
    disabled={disabled}
  >
    <span>{cta}</span>
  </StyledButton>
)

export default Button
