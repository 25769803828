import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { color, fonts } from '../../theme'

const StyledPagination = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;

  max-width: 600px;
  margin: 5% auto;
  color: ${color('desDarkBlue')};
  font-family: ${fonts('secondary')};
  font-size: 16px;

  div.text {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  p.sub-text {
    text-align: center;
    font-size: 12px;
    margin: unset;
  }

  div.graph {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
  }

  div.cross-bar {
    display: flex;
    width: 33%;
    height: 3px;
    align-self: center;
    background-color: ${color('lightGrey')};
  }
  div.dots {
    position: absolute;
    top: -10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  div.dot {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${color('lightGrey')};
  }
  div.inner-dot {
    position: absolute;
    left: 25%;
    top: 25%;
    height: 50%;
    width: 50%;
    border-radius: 50%;
    background-color: ${color('white')};
  }
`

const Pagination = ({ active, done }) => {
  const [pages] = useState(['Career', 'Courses', 'Results'])

  const activeStyle = {
    color: '#556685'
  }

  const activeDot = {
    backgroundColor: done ? 'green' : '#5a55ab',
    boxShadow:
      '0 2.1px 3.5px 0 rgba(0, 0, 0, 0.04), 0 4.6px 7.2px 0 rgba(0, 0, 0, 0.05), 0 7.9px 11.3px 0 rgba(0, 0, 0, 0.06), 0 12.3px 16.2px 0 rgba(0, 0, 0, 0.06), 0 19.3px 23.2px 0 rgba(0, 0, 0, 0.06), 0 34.2px 36.5px 0 rgba(0, 0, 0, 0.06), 0 100px 80px 0 rgba(0, 0, 0, 0.07)'
  }

  const setColor = (page, active) =>
    pages.indexOf(page) < pages.indexOf(active)
      ? 'green'
      : null

  return (
    <StyledPagination active={active}>
      <div className="graph">
        <div
          className="cross-bar left"
          style={{
            backgroundColor:
              active === 'Courses' || active === 'Results' ? 'green' : null
          }}
        ></div>
        <div
          className="cross-bar right"
          style={{
            backgroundColor: active === 'Results' ? 'green' : null
          }}
        ></div>
        <div className="dots">
          {pages.map((page, idx) => (
            <div
              key={page}
              className="dot"
              style={
                active === page
                  ? activeDot
                  : { backgroundColor: setColor(page, active) }
              }
            >
              <div className="inner-dot"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="text">
        {pages.map((page, i) => (
          <div key={page} style={active === page ? activeStyle : null}>
            {page}
            <p className="sub-text">Step {i + 1}</p>
          </div>
        ))}
      </div>
    </StyledPagination>
  )
}

export default Pagination
