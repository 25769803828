import React from 'react'
import styled from 'styled-components/macro'
import { color, breakpoints } from '../../theme'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  max-width: 1135px;
  padding: 2px 2%;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${color('white')};
  box-shadow: 0 4px 8px 0 rgba(0, 8, 32, 0.08), 0 1px 4px 0 rgba(0, 8, 32, 0.12);

  @media ${breakpoints('md')} {
    padding: 2px 32px;
    /* height: 100%; */
  }
`

const CardContainer = ({ children, blurry }) => <Card blurry>{children}</Card>

export default CardContainer
