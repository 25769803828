import React, { useState, useEffect } from 'react'
import { withRouter, Redirect, Link } from 'react-router-dom'
import {
  Button,
  ButtonContainer,
  Title,
  ArrowIcon,
  Pagination,
  Container,
  PurpleText,
  TextCenter,
  ShowCaseContainer,
  MobileMargin,
  SalaryChart,
  StyledH4
} from '../ui-components'
import styled from 'styled-components/macro'
import { fonts, color, breakpoints } from '../../theme'

const TabsContainer = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  justify-content: center;

  div.tabs-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* max-width: 513px; */
    margin: auto;
    border-radius: 25px;
    background-color: ${color('white')};

    @media ${breakpoints('md')} {
      margin: 0 auto;
      flex-direction: row;
      border-radius: 100px;
    }
  }

  div.tab {
    @media ${breakpoints('md')} {
      /* padding: 0 1.25rem; */
    }

    /* width: 171px; */
  }
`

const CareerTab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 13px 56px;
  text-transform: capitalize;
  background-color: ${props =>
    props.active ? color('blueyGreen') : 'transparent'};
  color: ${props => (props.active ? color('white') : color('desDarkBlue'))};
  font-family: ${fonts('primaryBold')};
  font-weight: 700;
  font-size: 0.87rem;
  line-height: 1.63;
  letter-spacing: 0.13px;
  width: 171px;
  height: 42px;
  white-space: nowrap;
  border-radius: ${props => (props.active ? '100px' : '0')};
`

const UlList = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '50%',
  fontFamily: fonts('secondary'),
  fontSize: '16px',
  color: color('darkblue'),
  paddingLeft: '20px',
  listStyle: 'none',
  textAlign: 'justify',

  'li.line': {
    margin: '10px auto 10px 0',
    maxWidth: '325px',
    color: color('darkBlue'),
    '::before': {
      content: "'\\2022'",
      color: color('lightGreen'),
      fontWeight: 'bold',
      display: ' inline-block',
      width: '1em',
      marginLeft: '-1em'
    }
  }
})

const StyledH3 = styled.h3`
  font-family: ${fonts('primaryBold')};
  color: ${color('black')};
  margin: 0;
`

const salaries = {
  'Web Developer': {
    salary1: 40,
    salary2: 54
  },
  'Data Analyst': {
    salary1: 45,
    salary2: 60
  },
  'Data Scientist': {
    salary1: 50,
    salary2: 64
  },
  'UI/UX Designer': {
    salary1: 38,
    salary2: 51
  },
}

const CareerMatch = ({ location: { state } }) => {
  const [career, setCareer] = useState('Web Developer')
  const [tasks, setTasks] = useState([])
  const [careerLink, setCareerLink] = useState('')

  useEffect(() => {
    document.body.className = ''
  }, [])

  const webDevTasks = [
    'Create code that builds the websites of the future.',
    'Work remotely or in the office.',
    'Work in startups, corporates, or as a freelancer.',
    'Work on the front-end (visuals, design, UI/UX), back-end (builds and maintains the technology that powers the website), or do both as a full-stack developer.',
    'Work with designers, product managers, and other developers.',
    'Learn lots of new skills like HTML, JavaScript, Ruby on Rails, and C++.'
  ]

  const dataAnalystTasks = [
    'A data analyst is someone who collects, processes and performs statistical analyses of data. ',
    'Translate numbers and data into plain English in order to help organizations and companies understand how to make better business decisions.',
    'Study what’s happening now to identify trends and make predictions about the future.',
    'Clean and dissect data to remove irrelevant information.',
    'Analyzing and interpreting results using statistical tools and techniques.',
    'Learn Python, SQL, R, and machine learning.'
  ]

  const dataScienceTasks = [
    'Interpret data sets to help businesses make better strategic decisions.',
    'Data scientists collect and report on data, and communicate their findings to both businesses and technology leaders in a way that can influence how an organization approaches a business challenge.',
    'Examine data from multiple angles to uncover problems, opportunities, and trends.',
    'Use complicated analytics programs, machine learning and statistical methods to prepare data.',
    'Learn statistics, database management, machine learning, and data wrangling.'
  ]

  const designerTasks = [
    'Be in charge of designing the look, feel, and experience of a website.',
    'Improve user satisfaction with a product by making its usability, accessibility, and interaction better.',
    'Work in a large team, solo, or as a freelancer.',
    'Expect to do user research, design, testing, and implementation.',
    'Learn  business analysis, project management, wiring framing, psychology, and user experience testing.'
  ]

  const webdevLink =
    'https://skillcrush.com/blog/tech-101-what-is-a-web-developer/'
  const dataScienceLink =
    'https://skillcrush.com/blog/how-to-become-a-data-scientist/'
  const dataAnalystLink =
    'https://www.rasmussen.edu/degrees/technology/blog/what-does-a-data-analyst-do/'
  const designerLink = 'https://skillcrush.com/blog/how-to-start-a-ux-career/'
  const designerDiffLInk =
    'https://skillcrush.com/blog/difference-between-ux-and-ui/'

  useEffect(() => {
    setTasks(webDevTasks)
  }, [])

  useEffect(() => {
    if (career === 'Data Analyst') {
      setTasks(dataAnalystTasks)
      setCareerLink(dataAnalystLink)
    }
    if (career === 'Web Developer') {
      setTasks(webDevTasks)
      setCareerLink(webdevLink)
    }
    if (career === 'Data Scientist') {
      setTasks(dataScienceTasks)
      setCareerLink(dataScienceLink)
    }
    if (career === 'UI/UX Designer') {
      setTasks(designerTasks)
      setCareerLink(designerLink)
    }
  }, [career])

  // ROUTE SECURITY: PREVENTING THE USER FROM ENTERING FROM A POINT IN THE MIDDLE
  if (!state) {
    return <Redirect to="/" />
  }

  const { resultId } = state

  const careers = [
    'Web Developer',
    'Data Analyst',
    'Data Scientist',
    'UI/UX Designer'
  ]

  // const webDevTasks = [
  //   'Implement new features and resolve problems',
  //   'Review code and refactor code',
  //   'Learn new libraries and framework',
  //   'Work with Designers, Product Managers and other developers'
  // ]

  return (
    <Container match>
      <Title>
        <div>You've matched with {careers.length} careers.</div>
      </Title>
      <TabsContainer>
        <div className="tabs-holder">
          {careers.map(careerName => (
            <CareerTab
              key={careerName}
              active={careerName === career}
              onClick={() => setCareer(careerName)}
            >
              {careerName}
            </CareerTab>
          ))}
        </div>
      </TabsContainer>
      <div>
        <TextCenter>
          <ShowCaseContainer match>
            <MobileMargin>
              <div
                style={{
                  display: 'flex',
                  flexDirection: ' column',
                  flexGrow: '2'
                }}
              >
                <StyledH3>Your day as {career}</StyledH3>
                <UlList>
                  {tasks.map((task, i) => (
                    <li className="line" key={i}>
                      {task}
                    </li>
                  ))}
                </UlList>
                <div style={{ marginTop: '10px' }}>
                  <a
                    href={careerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <PurpleText>
                      Learn more about being a{' '}
                      <span style={{ textTransform: 'capitalize' }}>
                        {career}
                      </span>
                      <ArrowIcon />
                    </PurpleText>
                  </a>
                </div>
                {career === 'UI/UX Designer' && (
                  <div style={{ marginTop: '10px' }}>
                    <a
                      href={designerDiffLInk}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <PurpleText>
                        What’s the difference between UX and UI?
                        <ArrowIcon />
                      </PurpleText>
                    </a>
                  </div>
                )}
              </div>
            </MobileMargin>
            <MobileMargin>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <SalaryChart
                  career={career}
                  {...salaries[career]}
                />
                {/* <div className="asset-placeholder"></div> */}
              </div>
            </MobileMargin>
          </ShowCaseContainer>
        </TextCenter>

        <div></div>
      </div>
      <ButtonContainer>
        <Link
          style={{ color: '#5a55ab' }}
          to={{
            pathname: '/recommendations/action',
            state: {
              // ADDED PROPERTY FOR ROUTE SECURITY
              resultId
            }
          }}
        >
          <Button cta="Continue" />
        </Link>
      </ButtonContainer>

      <Pagination active="Career" />
    </Container>
  )
}

export default withRouter(CareerMatch)
