import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { color, fonts, breakpoints } from '../../theme'
import { withRouter, Redirect, Link } from 'react-router-dom'
import {
  Button,
  Container,
  ButtonContainer,
  Title,
  SubTitle,
  ShowCaseContainer,
  Pagination,
  PurpleText,
  BlackTitle,
  TextCenter,
  StyledH4
} from '../ui-components'
import { useSpring, useChain, animated } from 'react-spring'

const BootcampDescription = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.8;
  font-family: ${fonts('secondary')};
`

const WhiteSection = styled.div`
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  position: relative;
  left: 0;
  // width: 100vw;
  // transform: translateX(-17%);
  margin: 4% -17vw 8%;
  background-color: white;
  padding: 3% 11%;

  div.background {
    position: absolute;
    height: 100%;
    width: 120vw;
    background-color: ${color('white')};
    z-index: 0;
  }
  div.holder {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // max-width: 1135px;
    flex-wrap: wrap;
    z-index: 1;

    @media ${breakpoints('md')} {
      justify-content: space-evenly;
      flex-direction: row;
    }
    @media ${breakpoints('xl')} {
      max-width: 1600px;
    }
  }

  div.content {
    font-family: ${fonts('secondary')};
    font-size: 1rem;
    color: ${color('darkBlue')};
    margin: 5% 0;
  }
`

const MarketChart = styled.div`
  display: flex;
  width: 330px;
  flex-direction: column;

  @media screen and (min-width: 565px) {
    max-width: 464px;
    width: unset;
  }
  p {
    margin: 0;
  }

  p.name {
    font-family: ${fonts('primaryBold')};
    font-size: 16px;
  }

  p.cost {
    font-family: ${fonts('primaryRegular')};
    font-size: 10px;
  }
  p.time {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
  }

  div.bar-background {
    display: flex;
    position: relative;
    justify-content: flex-end;

    height: 32px;
    background-color: ${color('white')};
    color: ${color('blackyBlue')};
    margin: 3% 7px;
    padding: 7px 20px;

    @media screen and (min-width: 565px) {
      width: 437px;
    }
  }

  div.bar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    color: ${color('white')};
    background-color: ${color('blueyGreen')};
    border-radius: 0 10px 10px 0;
    padding-left: 20px;
  }
`
const FactHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-self: auto;
  max-width: 75%;
  margin: 6% 0;

  @media ${breakpoints('md')} {
    max-width: 27%;
  }
  @media screen and (min-width: 648px) {
    margin: 4% 0 2%;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: #5a55ab;
    font-weight: bold;
  }

  a:hover {
    opacity: 0.7;
  }
`

const learnTypes = [
  {
    name: 'Bootcamp',
    time: '3',
    cost: '0'
  },
  {
    name: 'Self-taught',
    time: '9',
    cost: '0'
  },
  {
    name: 'College',
    time: '48',
    cost: '45k'
  }
]

const facts = [
  {
    title: 'Get hired fast',
    content:
      'Bootcamp graduates have up to a 96% chance to get a job in their trained profession within 3 months.'
    // link: 'https://www.coursereport.com/reports/coding-bootcamp-job-placement-report-2019'
  },
  {
    title: 'Hands-on experience',
    content:
      'Do projects that give you real practical education to prepare you for future work.'
  },
  {
    title: 'Financial aid from the Bundesagentur für Arbeit.',
    content: (
      <span>
        The Bundesagentur für Arbeit can cover all your course costs, as well as
        pay you <strong>75% of your current salary</strong> during your
        training.
      </span>
    )
    // link: 'https://www.ibb.com/foerderung/bildungsgutschein'
  }
]

const getWidth = idx => (idx === 0 ? '28%' : idx === 1 ? '45%' : '96%')

const JoinBootcamp = ({ location: { state } }) => {
  useEffect(() => {
    window.scrollTo(0, 0)

    document.body.style.overflowX = 'hidden'

    return () => {
      document.body.style.overflowX = 'unset'
    }
  }, [])

  const fadeIn = { opacity: 1, from: { opacity: 0 } }

  const subtitleRef = useRef()
  const subtitleFade = useSpring({ ...fadeIn, ref: subtitleRef })

  const titleRef = useRef()
  const titleFade = useSpring({ ...fadeIn, ref: titleRef })

  const midSectionRef = useRef()
  const midSectionFade = useSpring({ ...fadeIn, ref: midSectionRef })

  const restRef = useRef()
  const restFade = useSpring({ ...fadeIn, ref: restRef })

  useChain([subtitleRef, titleRef, midSectionRef, restRef], [0, 1.5, 3, 5])

  // ROUTE SECURITY: PREVENTING THE USER FROM ENTERING FROM A POINT IN THE MIDDLE
  if (!state) {
    return <Redirect to="/" />
  }

  const { resultId } = state

  return (
    <Container>
      <Title>
        <div>
          <animated.span style={subtitleFade}>
            {' '}
            <span style={{ fontSize: '65%' }}>
              Based on your answers, we recommend that you
            </span>{' '}
          </animated.span>
          <br />
          <animated.span style={titleFade}>
            Join a career bootcamp.
          </animated.span>
        </div>
      </Title>
      {/* <SubTitle centered>
        Career bootcamps are intensive courses that are certified by the
        Bundesagentur für Arbeit to get you a new job in the profession of your
        choosing, very quickly.
      </SubTitle> */}
      <br />
      <br />
      <TextCenter>
        <animated.div style={midSectionFade}>
          <ShowCaseContainer>
            <div className="inner-left">
              <BootcampDescription>
                Career bootcamps are intensive courses <br />
                that are certified by the Bundesagentur für Arbeit
                <br />
                to get you a new job in the profession of your choosing,
                <br />
                very quickly.
              </BootcampDescription>
            </div>
            <div className="inner-right">
              <StyledH4>Time to market</StyledH4>
              <MarketChart>
                {learnTypes.map(({ name, time, cost }, idx) => (
                  <div key={name} className="bar-background">
                    <div
                      className="bar-content"
                      style={{ width: getWidth(idx) }}
                    >
                      <p className="name">{name}</p>
                      {/* <p className="cost">Cost: {cost}</p> */}
                    </div>
                    <p
                      className="time"
                      style={idx === 2 ? { zIndex: 1, color: 'white' } : null}
                    >
                      {time} months
                    </p>
                  </div>
                ))}
              </MarketChart>
            </div>
          </ShowCaseContainer>
        </animated.div>
        <br />
        <br />
        <animated.div style={restFade}>
          <WhiteSection>
            <span style={{ textAlign: 'center' }}>
              <Title>
                <span style={{ fontSize: '65%' }}>
                  Why should you join a bootcamp?
                </span>
              </Title>
            </span>
            {/* <div className="background"></div> */}
            <div className="holder">
              {facts.map(({ title, content /*, link*/ }) => (
                <FactHolder key={title}>
                  <BlackTitle>{title}</BlackTitle>
                  <div className="content">{content}</div>
                  {/* {link && <a target="_blank" rel="noopener noreferrer" href={link}>Learn more</a>} */}
                </FactHolder>
              ))}
            </div>
          </WhiteSection>
        </animated.div>
      </TextCenter>

      <animated.div style={restFade}>
        <ButtonContainer>
          <Link
            style={{ color: '#5a55ab' }}
            to={{
              pathname: '/form',
              state: {
                formId:
                  process.env.NODE_ENV === 'production'
                    ? 'KKSw0mvi'
                    : 'EuMduLTX',
                next: `/result/${resultId}`,
                resultId
              }
            }}
          >
            <Button cta="Match with Bootcamps" />
          </Link>
        </ButtonContainer>

        <Pagination active="Courses" />
      </animated.div>
    </Container>
  )
}

export default withRouter(JoinBootcamp)
