export const theme = {
  main: {
    colors: {
      brandBackground: '#f7f6fb',
      purple: '#5a55ab',
      softPurple: '#f6f8fc',
      black: '#152540',
      golden: '#f4f2ed',
      starsGolden: '#FEBB5B',
      desDarkBlue: '#8494b2',
      darkBlue: '#556685',
      blackyBlue: '#3b4b66',
      blueyGreen: 'rgb(41, 163, 153)',
      lightGreen: '#54d4c9',
      grey: 'rgb(151, 151, 151)',
      green: '#128945',
      lightGrey: '#d9e1ee',
      white: '#fff',
      stripes: 'rgba(140, 136, 196, 0.4)'
    },
    fonts: {
      primaryBlack: 'Poppins Black',
      primaryBold: 'Poppins Bold',
      primaryRegular: 'Poppins',
      secondary: 'PT Sans',
      nunito: 'Nunito Sans'
    },
    breakpoints: {
      //HORIZONTAL BREAKPOINTS
      md: 'screen and (min-width: 892px)',
      ml: 'screen and (min-width: 1024px)',
      lg: 'screen and (min-width: 1200px)',
      l: 'screen and (min-width: 1400px)',
      xl: 'screen and (min-width: 2560px)',
      ///VERTICAL BREAKPOINTS
      vl: 'screen and (min-height: 1200px)',
      vlhl: 'screen and (min-height: 1200px) and (min-width: 1400px)'
    }
  }
}

export const color = variant => props => props.theme.main.colors[variant]
export const fonts = variant => props => props.theme.main.fonts[variant]
export const breakpoints = variant => props =>
  props.theme.main.breakpoints[variant]

export default theme
