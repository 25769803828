import React, { useEffect } from 'react'
import Modal from 'react-modal'
import checkIcon from '../../../assets/bootcamp/checkComplete.svg'

Modal.setAppElement('#root')

const InfoModal = ({
  // modalTitle,
  // modalMessage,
  setModalTitle,
  setModalMessage,
  isOpen,
  setIsOpen
}) => {

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden'

  //   return () => (document.body.style.overflow = 'unset')
  // }, [])

  function closeModal() {
    setIsOpen(false)
    // setModalTitle('')
    // setModalMessage('')
  }

  const imgStyle = {
    display: 'flex',
    height: '36px',
    padding: '0 0.5rem'
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Contact Modal"
      >
        <h2 className="modal-title">Received!</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <img style={imgStyle} src={checkIcon} alt="check" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '1rem 0' }}>
              Check your email for more information.
            </div>
            {/* <div style={{ textAlign: 'center' }}>
              <button onClick={closeModal}>close</button>
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InfoModal
