import React from 'react'
import styled from 'styled-components/macro'
import { Button, ButtonContainer, Title, SubTitle } from '../ui-components'
import heroImage from '../../assets/bootcamp/map-min.png'
// import texture from '../../assets/landing/texture.svg'
import { breakpoints, color, fonts } from '../../theme'
import { Link } from 'react-router-dom'

const StyledSection = styled.section`
  padding-top:5%;
  display: flex;
  /* margin: 0 0 ${props => (props.top ? '120px' : '30px')}; */
  min-height: 500px;
  max-width: 1600px;
  margin: 0 auto;
  
  @media ${breakpoints('md')}{
    margin: unset;
  }
  

  @media screen and (min-width: 1400px) {
    position: relative;
    width:100%;
  }
`

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;

  @media ${breakpoints('md')} {
    justify-content: space-between;
  }
  /* @media screen and (min-width: 1400px) {
    margin-top: 50px;
  } */
`

const Presentation = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  height: 100%;
  justify-content: space-around;
  /* margin: 80px 0 auto; */
  /* max-width: 521px; */

  @media ${breakpoints('md')} {
    height: 100%;
    justify-content: unset;
    text-align: unset;
    width: 50%;
    /* margin: 65px 35px; */
  }
  /* @media screen and (min-width: 1400px) {
    margin: 0 50px 0 14%;
  } */
`
// const HeroImage = styled.div`
//   display: none;
//   width: 55%;
//   max-width: 900px;
//   flex-direction: column;
//   align-self: self-end;
//   text-align: end;

/* div.texture-container {
    display: flex;
    flex-direction: row;
  }

  div.texture {
    display: none !important;
   
    height: auto;
    min-width: 100px;
    display: block;
    z-index: -1;
    @media ${breakpoints('lg')} {
      display: block !important;
    }
  } */

/* @media ${breakpoints('md')} { style={{ padding: '0 0 0 63px', margin: '20px 0' }}
    display: unset;
  }

  @media ${breakpoints('lg')} {
    min-width: 880px;
  }
` */

const StyledMapContainer = styled.div`
display:none;
  position: relative;
  /* transform: translate(-390px, -30px); */
  width: 55%;


  @media ${breakpoints('md')}{
    display:flex;
    min-width: 540px; 
  }

  /* top: 70px;
  right: -140px; */

  /* @media ${breakpoints('ml')} { */
    /* right: -63px; */
    /* transform: translate(-140px, -40px); */
  /* } */

   @media ${breakpoints('lg')} {  
    width:unset;
    
     /* transform: translate(-150px, -80px);   */
    
   } 

  /* @media screen and (min-width: 1400px) { */
    /* transform: translate(-145px, -130px); */
  /* } */
`

const MapImage = styled.img`
  /* height: 695px; */
  position: absolute;
  transition: transform 0.2s ease-in-out;
  /* transform: translate(-130px, -130px); */

  @media ${breakpoints('md')} {
    transform: translate(-22%, -17%);
    height: 83%;
  }

  @media screen and (min-width: 1065px) {
    transform: translate(-12%, -11%);
    /* height: 90%; */
  }
  @media screen and (min-width: 1115px) {
    height: 105%;
  }

  @media ${breakpoints('lg')} {
    transform: translate(-20%, -11%);
    height: 100%;
  }

  @media screen and (min-width: 1400px) {
    transform: translate(-16%, -13%);
    height: unset;
  }
`

const StyledUl = styled.ul`
  color: ${color('darkBlue')};
  list-style-type: none;
  padding: 0;
  font-size: 16px;
  font-family: ${fonts('secondary')};

  li.list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: start;
    max-width: 500px;
    margin: auto;

    @media screen and (min-width: 570px) and (max-width: 891px) {
      max-width: 500px;
    }
    @media ${breakpoints('md')} {
      max-width: unset;
    }
  }

  @media ${breakpoints('md')} {
    white-space: nowrap;
  }
  @media ${breakpoints('vl')} {
    margin: 4% 0;
  }
  li {
    padding: 5px 0;
  }
`

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background: repeating-linear-gradient(
    -55deg,
    rgba(140, 136, 196, 0.4),
    rgba(140, 136, 196, 0.4),
    #f7f6fb 1px,
    #f7f6fb 4px,
    rgba(140, 136, 196, 0.4) 4px
  );
  border-radius: 50%;
  position: absolute;
  margin: 0 12px 0 0;
  color: ${color('purple')};
  font-size: 24px;
  font-family: ${fonts('primaryBlack')};
`

const StyledSpan = styled.span`
  max-width: 15.625rem;
  padding: 0 0 0 63px;
  margin: 20px auto;

  @media screen and (min-width: 570px) {
    max-width: unset;
    margin: 20px 0;
  }
`

const listItems = [
  'Match your background with a government supported career',
  'Find certified courses the government will pay for',
  'Receive a personal development plan to get you started'
]

const resultId =
  Math.random().toString(36).substring(2, 5) +
  Math.random().toString(36).substring(2, 5)

const Landing = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 892)

  // useEffect(() => {
  //   setMobileBody(isMobile)
  //   return () => {
  //     setMobileBody(false)
  //   }
  // }, [isMobile])

  // useEffect(() => {
  //   // window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //     document.body.style.overflow = 'unset'
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log(isMobile)
  // }, [isMobile])

  // const setMobileBody = mobile => {
  //   const root = document.getElementById('root')
  //   document.body.style.overflow = mobile ? 'unset' : 'hidden'
  //   root.style.height = mobile ? 'unset' : '100vh'
  //   root.style.position = mobile ? 'relative' : 'initial'
  //   root.style.width = mobile ? 'unset' : '100vw'
  //   root.style.paddingBottom = '6rem'
  //   root.style.display = 'flex'
  //   root.style.justifyContent = 'flex-start'
  //   root.style.minHeight = 'calc(100vh - 6rem)'
  //   root.style.flexDirection = 'column'
  //   root.style.backgroundColor = '#f7f6fb'

  // root.style.paddingBottom = '6rem'
  // }
  // const handleResize = () => {
  //   console.log('listening')
  //   if (window.innerWidth >= 892) {
  //     setIsMobile(false)
  //   } else {
  //     setIsMobile(true)
  //   }
  // }
  // const lockBody = () => {
  //   console.log('listening')
  //   return window.innerWidth >= 892
  //     ? (document.body.style.overflow = 'hidden')
  //     : (document.body.style.overflow = 'unset')
  // }

  return (
    <>
      <StyledSection top>
        <Hero>
          <Presentation>
            <Title>
              Change your career <br /> in 3 steps
            </Title>
            <SubTitle>
              And get over €10,000 of financial support from the Bundesagentur
              für Arbeit.
            </SubTitle>
            <StyledUl>
              {listItems.map((text, idx) => (
                <li className="list-item" key={idx}>
                  <StyledCircle>{(idx + 1).toString()}</StyledCircle>
                  <StyledSpan>{text}</StyledSpan>
                </li>
              ))}
            </StyledUl>
            <Link
              style={{ color: '#5a55ab' }}
              to={{
                pathname: '/form',
                state: {
                  formId: process.env.NODE_ENV === 'production'
                    ? 'KdMkFHVw'
                    : 'BUw0Okko',
                  next: '/recommendations/match',
                  resultId
                }
              }}
            >
              <ButtonContainer landing>
                <Button cta="Get started" />
              </ButtonContainer>
            </Link>
          </Presentation>

          {/* <HeroImage> */}
          <StyledMapContainer>
            <MapImage alt="start your career" src={heroImage} />
          </StyledMapContainer>
          {/* </HeroImage> */}
        </Hero>
      </StyledSection>
    </>
  )
}

export default Landing
