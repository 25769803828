import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'

const ShowCase = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 350px;
  max-width: 950px;
  justify-content: space-between;
  align-items: center;
  margin: ${props => (props.match ? '8% auto 4%' : 'auto')};

  @media ${breakpoints('md')} {
    flex-direction: row;
    align-items: ${props => (props.match ? 'flex-start' : 'center')};
  }

  @media ${breakpoints('xl')} {
    max-width: 1200px;
  }

  div.inner-showcase-left {
    display: flex;
    align-items: center;

    @media ${breakpoints('md')} {
      padding-left: 100px;
    }
  }
  div.inner-showcase-right {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media ${breakpoints('md')} {
      padding-right: 100px;
    }
  }
`

const ShowCaseContainer = ({ children, match }) => (
  <ShowCase match={match}>{children}</ShowCase>
)

export default ShowCaseContainer
