import React, { useState, useEffect } from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { Redirect, withRouter } from 'react-router-dom'
import { Loading } from '../Result/components/Loading'

const Form = ({
  location: {
    state
  }
}) => {
  const [submitted, setSubmitted] = useState(null)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    let timeout

    if (submitted) {
      timeout = setTimeout(() => setRedirect(true), 3000)
    }

    return () => (timeout ? clearTimeout(timeout) : null)
  }, [submitted])

  if (!state) {
    return <Redirect to="/" />
  }

  const {
    formId,
    next,
    resultId
  } = state

  return (
    <div>
      {!submitted
        ? (
          <>
            <ReactTypeformEmbed
              url={`https://innential.typeform.com/to/${formId}?resultid=${resultId}`}
              onSubmit={() => {
                setSubmitted(true)
              }}
              hideHeader
              opacity={0}
            />
          </>
        )
        : (
          <Loading />
        )
      }
      {redirect && (
        <Redirect
          to={{
            pathname: next,
            state: { // ADDED PROPERTY FOR ROUTE SECURITY
              resultId
            }
          }}
        />
      )}
    </div>
  )
}

export default withRouter(Form)
