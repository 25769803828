import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'
import bootcamp1 from '../../assets/bootcamp/bootcamp1-min.png'
import bootcamp2 from '../../assets/bootcamp/bootcamp2-min.png'
import bootcamp3 from '../../assets/bootcamp/bootcamp3-min.png'
import { CardContainer } from '../ui-components'

//for staging maybe better store the placeholder images in the server,
// and in FrontEnd reference them ?

const placeholderImages = [bootcamp1, bootcamp2, bootcamp3]

const StyledImg = styled.img`
  width: 280%;
  min-width: 596px;

  @media screen and (min-width: 470px) {
    width: 165%;
  }

  @media ${breakpoints('md')} {
    width: 100%;
  }
`

const BlurryCard = React.memo(() => {
  const getIndex = () => {
    const index = Math.floor((Math.random() * 10) / 3)
    return index > 2 ? 1 : index
  }
  return (
    <div style={{ minHeight: '85px', margin: '0 0 20px 0' }}>
      <CardContainer blurry>
        <StyledImg
          src={`${placeholderImages[getIndex()]}`}
          alt="bootcamp info"
        />
      </CardContainer>
    </div>
  )
})

export default BlurryCard
