import React from 'react'
import styled from 'styled-components'
import { fonts, color, breakpoints } from '../../theme'

const Title = styled.p`
  font-family: ${fonts('primaryBlack')};
  font-size: ${props =>
    props.result && props.needFormatting
      ? '0.75rem'
      : props.result
      ? '1rem'
      : '1.25rem'};
  color: ${color('black')};
  max-width: 100%;
  width: ${props => (props.result ? 'max-content' : 'unset')};

  @media ${breakpoints('md')} {
    font-size: 1.25rem;
  }
`

const BlackTitle = ({ children, result, needFormatting }) => (
  <Title result={result} needFormatting={needFormatting}>
    {children}
  </Title>
)

export default BlackTitle
