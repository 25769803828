import React from 'react'
import styled from 'styled-components'
import { color, fonts } from '../../theme'

const Text = styled.div`
  font-family: ${fonts('primaryBold')};
  color: ${color('purple')};
  font-size: 14px;
`

const PurpleText = ({ children }) => <Text>{children}</Text>

export default PurpleText
