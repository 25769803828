import React from 'react'
import styled from 'styled-components'
import { breakpoints, color, fonts } from '../../theme'

const StyledParagraph = styled.p`
  font-family: ${fonts('primaryRegular')};
  margin: 33px 0;
  padding: 0;
  padding-right: 0;
  font-size: 16px;
  font-weight: 400;
  color: ${color('black')};
  text-align: ${props => (props.centered ? 'center' : 'inherit')};

  @media ${breakpoints('md')} {
    padding-right: 48px;
  }
`

const SubTitle = ({ children, centered }) => (
  <StyledParagraph centered={centered}>{children}</StyledParagraph>
)

export default SubTitle
