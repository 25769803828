import React from 'react'
import styled from 'styled-components/macro'
import { fonts, color } from '../../theme'

const TextH4 = styled.h4`
  color: ${color('desDarkBlue')};
  font-family: ${fonts('primaryRegular')};
  text-align: center;
  margin-top: 0;
  span.bold {
    font-family: ${fonts('primaryBold')};
    color: ${color('black')};
  }
`

const StyledH4 = ({ children }) => <TextH4>{children}</TextH4>

export default StyledH4
