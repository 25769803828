import React from 'react'
import styled from 'styled-components/macro'
// import facebokIcon from '../../assets/bootcamp/facebook.svg'
import linkedinIcon from '../../assets/bootcamp/linkedin.svg'
import { ArrowIcon } from '../ui-components'
import { fonts, breakpoints, color } from '../../theme'

const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  height: 6rem;
  display: flex;
  width: 100%;
  background-color: ${color('white')};

  @media ${breakpoints('lg')} {
    padding: 0;
  }
  div {
    display: flex;
    align-items: center;
    font-family: ${fonts('primaryRegular')};
    font-size: 12px;
  }
  div.footer-container {
    /* max-width: 1024px; */
    margin: 0 auto;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    @media ${breakpoints('lg')} {
      width: 100%;
      margin: 0 17%;
    }
    @media ${breakpoints('xl')} {
      max-width: 1600px;
      margin: 0 auto;
      height: 8rem;
    }
    div p.brand {
      color: ${color('darkBlue')};
      margin-right: 15px;
    }
    a {
      text-decoration: none;
      color: #5a55ab;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer-container">
        <div>
          <p className="brand">Innential 2020</p>
          <a href="https://www.linkedin.com/company/innential/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="social-li" />
          </a>
        </div>
        <a href="https://www.iubenda.com/privacy-policy/66684087" target="_blank" rel="noopener noreferrer">
          <p>
            <strong>Legal notice</strong>
            <ArrowIcon />
          </p>
        </a>
      </div>
    </StyledFooter>
  )
}

export default Footer
