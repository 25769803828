import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints, color, fonts } from '../../theme'

const StyledH1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  line-height: 60px;
  color: ${color('black')};
  font-family: ${fonts('primaryBlack')}, sans-serif;
  white-space: wrap;

  @media ${breakpoints('md')} {
    font-size: 3rem;
    line-height: 76px;
  }
  @media ${breakpoints('xl')} {
    font-size: 4rem;
  }

  div {
    text-align: center;
  }

  /*@media ${breakpoints('lg')} {
    white-space: nowrap;
  }*/
`

const Title = ({ children }) => <StyledH1>{children}</StyledH1>

export default Title
