import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'

const Content = styled.div`
  margin: 2rem 0;

  @media ${breakpoints('md')} {
    margin: 0;
  }
`

const MobileMargin = ({ children }) => <Content>{children}</Content>

export default MobileMargin
