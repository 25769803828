import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router-dom'
import { Landing, Result, Form, CareerMatch, JoinBootcamp } from './components'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import styled, { ThemeProvider } from 'styled-components/macro'
import theme, { color, breakpoints } from './theme'
import GlobalFonts from './assets/fonts/fonts'
import './App.css'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-107489291-7');

const PageListener = withRouter(({ location }) => {
  const { pathname, search } = location

  useEffect(() => {
    ReactGA.pageview(pathname + search)
  }, [pathname, search])

  return null
})

const Container = styled.div`
  display: flex;
  height: 80%;
  position: relative;
  min-height: inherit;
  padding: 0 6%;
  /* max-width: 1120px; */
  background-color: ${color('brandBackground')};
  @media ${breakpoints('md')} {
    padding: 0 3%;
  }

  @media ${breakpoints('lg')} {
    margin: 0 auto;
    width: 66%;
  }
  @media ${breakpoints('xl')} {
    max-width: 1742px;
    width: unset;
  }

  @media ${breakpoints('vlhl')} {
    margin: 8% 15%;
  }

  @media screen and (min-height: 900px) and (max-width: 891px) {
    padding-top: 8%;
  }
`

// const AbsoluteWrapper = styled.div`
//   position: absolute;
//   width: 80%;
//   height: 80%;
//   top: 0;
// `

function App() {
  return (
    <Router>
      <PageListener />
      <GlobalFonts />
      <ThemeProvider theme={theme}>
        <Header text="Career Compass" />

        <Container>
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path="/form" exact>
              {/* <AbsoluteWrapper> */}
              <Form />
              {/* </AbsoluteWrapper> */}
            </Route>
            <Route path="/recommendations/match">
              <CareerMatch />
            </Route>
            <Route path="/recommendations/action">
              <JoinBootcamp />
            </Route>
            <Route path="/result/:resultId">
              <Result />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Container>
        <Footer />
      </ThemeProvider>
    </Router>
  )
}

export default App
