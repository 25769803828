import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'

const ButtonCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${props => (props.landing ? '12% 0' : '0 0 12%')};

  @media ${breakpoints('md')} {
    justify-content: ${props => (props.landing ? 'flex-end' : 'center')};
    margin-top: 30px;
    padding: 0;
  }
  @media ${breakpoints('lg')} {
    justify-content: ${props => (props.landing ? 'flex-start' : 'center')};
    margin-top: ${props => (props.landing ? '5%' : '0')};
  }
`

const ButtonContainer = ({ children, landing }) => (
  <ButtonCon landing={landing}>{children}</ButtonCon>
)

export default ButtonContainer
