import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'

const Text = styled.div`
  text-align: center;

  @media ${breakpoints('md')} {
    text-align: unset;
  }
`

const TextCenter = ({ children }) => <Text>{children}</Text>

export default TextCenter
