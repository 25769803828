import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../theme'
import logo from '../../assets/innential-logo-transparent.png'
import { Link } from 'react-router-dom'

const NavBar = styled.header`
  position: relative;
  display: flex;
  padding: 2rem;
  font-size: 13px;
  align-items: center;

  @media ${breakpoints('md')} {
    padding: 30px 0 30px 40px;
  }

  @media screen and (max-width: 380px) {
    padding: 18px;
  }

  div.header-container {
    display: flex;
    align-items: center;
    max-width: 1600px;
    margin: auto;
    width: 100%;
    @media ${breakpoints('xl')} {
      max-width: 2100px;
    }
  }

  a {
    text-decoration: none;
    color: unset;
  }
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  font-size: 20px;
  font-family: 'Poppins';
  color: #fff;
  border-radius: 4px;
  background-color: #29a399;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: center;
  letter-spacing: normal;
  padding: 5px 10px;

  @media screen and (max-width: 380px) {
    margin-left: 0;
    padding: 5px;
    font-size: 16px;
  }
`

const Header = ({ text }) => (
  <NavBar>
    <div className="header-container">
      <Link to="/">
        <TextHeader>
          <span>{text}</span>
        </TextHeader>
      </Link>
      <span style={{ padding: '0px 10px', fontFamily: 'Poppins' }}>by</span>
      <div>
        <a
          href="https://innential.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logo}
            alt="innential"
            style={{ height: '10px', width: '51px' }}
          />
        </a>
      </div>
    </div>
  </NavBar>
)

export default Header
