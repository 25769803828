import React from 'react'
import styled from 'styled-components/macro'
import { StyledH4 } from './'
import { color, fonts } from '../../theme'

const ChartContainer = styled.div`
  display: flex;
  width: 315px;
  justify-content: space-evenly;
  /* margin-left: 20px; */
`

const Bar = styled.div`
  height: ${props => (props.left ? '211px' : '106px')};
  width: 54px;
  background-color: ${props =>
    props.left ? color('blueyGreen') : color('desDarkBlue')};
  border-radius: 5px 5px 0 0;
`

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  font-size: 16px;
  height: min-content;
  color: ${props => (props.left ? color('blueyGreen') : color('desDarkBlue'))};
  /* margin-left: ${props => (props.left ? '0' : '40px')}; */
`

const BarTextContainer = styled.div`
  text-align: center;
  p {
    margin: 8px 0;
  }
  p.salary {
    font-family: ${fonts('primaryBold')};
  }
  p.experience {
    font-family: ${fonts('primaryRegular')};
  }
`

const SalaryChart = ({
  salary1 = 45,
  salary2 = 90,
  career = 'Web developer'
}) => {
  const salaryDifference = (salary2 / salary1).toFixed(1)

  return (
    <>
      <StyledH4>
        <span style={{ textTransform: 'capitalize' }}>{career}</span>s
      can <span className="bold">increase their salary</span> <br />{' '}
      by <span className="bold">{salaryDifference}x</span> after 5 years experience
    </StyledH4>
      <ChartContainer>
        <BarContainer>
          <Bar />
          <BarTextContainer>
            <p className="salary">€{salary1}k</p>
            <p className="experience">1 year of experience</p>
          </BarTextContainer>
        </BarContainer>
        <BarContainer left>
          <Bar left />
          <BarTextContainer>
            <p className="salary">€{salary2}k</p>
            <p className="experience">5 years of exp.</p>
          </BarTextContainer>
        </BarContainer>
      </ChartContainer>
    </>
  )
}

export default SalaryChart
