import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { BlackTitle, ArrowIcon, CardContainer } from '../ui-components'
import { useSpring, useTrail, animated } from 'react-spring'
// import { financialOptions } from '../Result/fixtureData'
import { fonts, color, breakpoints } from '../../theme'

const UpperCard = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 3% 0 1%;
  @media ${breakpoints('md')} {
    padding: 0;
  }

  div.content {
    display: flex;
    justify-content: space-around;
    width: 90%;
    align-items: center;
    padding: 2% 6% 2% 2%;

    @media ${breakpoints('md')} {
      justify-content: space-between;
      width: 100%;
    }
  }
`

const LowerCard = styled.div`
  display: flex;
  width: 100%;
  min-height: 5px;
  flex-direction: column;

  div.separator {
    border-top: 2px solid ${color('softPurple')};
    width: 100%;
    margin: 4% 0 3%;
  }

  div.lc-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3% 0;
    align-items: center;

    @media ${breakpoints('md')} {
      align-items: flex-start;
      padding: 0 0 3%;
    }
  }

  div.image img {
    width: 66px;
    @media ${breakpoints('md')} {
      width: 106px;
    }
  }

  div.finan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75%;
    text-align: center;
    margin-bottom: 2rem;

    @media ${breakpoints('md')} {
      flex-direction: row;
      width: 100%;
      padding: 0.55rem 0 0.55rem 2rem;
      text-align: start;
      margin-bottom: 0;
    }

    div.finan-name {
      @media ${breakpoints('md')} {
        width: 25%;
        padding-left: 10%;
      }
    }
  }

  div.comment-text {
    position: relative;

    font-family: ${fonts('primaryRegular')};
    color: ${color('darkBlue')};
    font-size: 0.625rem;

    @media ${breakpoints('md')} {
      font-size: 0.75rem;
      left: 3.5%;
      width: 50%;
    }
  }
`

const BlackSubtitle = styled.h5`
  font-family: ${props =>
    props.main ? fonts('primaryBlack') : fonts('primaryBold')};
  color: ${color('black')};
  font-size: 12px;
  margin-top: ${props => (props.main ? '0' : '')};
  overflow-wrap: anywhere;
  margin: ${props => (props.finan ? '0.5rem 0' : '1rem 0')};

  @media ${breakpoints('md')} {
    overflow-wrap: normal;
    margin: 1rem 0;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 50%;

  @media ${breakpoints('md')} {
    max-width: 40%;
  }

  div.img-container {
    display: none;
    align-items: center;
    margin-right: 5%;

    @media screen and (min-width: 380px) {
      display: flex;
    }

    @media ${breakpoints('md')} {
      display: flex;
      margin-right: 30px;
    }

    img {
      height: 42px;
      @media ${breakpoints('md')} {
        height: 75px;
      }
    }
  }

  div.image-placeholder {
    height: 75px;
    width: 75px;
    background: lightgray;
  }

  div.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 114px;
    overflow-wrap: anywhere;

    @media screen and (min-width: 450px) {
      overflow-wrap: normal;
    }

    p {
      margin: 0;
    }
  }
`

const DateDiv = styled.div`
  font-family: ${fonts('primaryBold')};
  color: ${color('darkBlue')};
  font-size: 0.75rem;
  max-width: 38%;
  text-align: center;
  @media ${breakpoints('md')} {
    max-width: 30%;
    font-size: 0.87rem;
  }
`

const CardText = styled.div`
  font-family: ${fonts('primaryRegular')};
  font-size: 0.625rem;
  margin: 0;
  color: ${color('blackyBlue')};
  width: ${props => (props.down ? '100%' : 'unset')};
  display: flex;
  justify-content: ${props => (props.down ? 'space-around' : 'unset')};
  flex-direction: ${props => (props.down ? 'row' : 'column')};

  @media ${breakpoints('md')} {
    font-size: 0.87rem;
  }
`

const ScheduleContainer = styled.div`
  display: ${props => (props.down ? 'flex' : 'none')};
  max-width: ${props => (props.up ? '33%' : 'unset')};
  width: ${props => (props.down ? '100%' : 'unset')};

  @media ${breakpoints('md')} {
    display: ${props => (props.up ? 'flex' : 'none')};
  }
`

const ResultCard = React.memo(({ bootcamp, idx }) => {
  const [isOpen, setIsOpen] = useState(idx === 0)
  // const [contentHeight, setContentHeight] = useState('100px')
  // const [ref, { height }] = useMeasure()

  useEffect(() => {
    return () => setIsOpen(false)
  }, [])

  const {
    bootcamp: title,
    source,
    startsAt,
    financing,
    duration,
    format
  } = bootcamp

  const needFormatting = title.length >= 14 && !/\s/.test(title)

  const formatNode = format.map((f, i) => <p key={i}>{f}</p>)

  const financialOptions = financing

  const cardStyle = useSpring({
    // height: isOpen ? `${contentHeight}px` : `139px`,
    marginBottom: '4%',
    cursor: 'pointer'
  })

  const trail = useTrail(financialOptions.length, {
    opacity: isOpen ? 1 : 0,
    height: isOpen ? `unset` : 0,
    transform: isOpen ? `translateY(-20%)` : `transform: translateY(0%)`
  })

  return (
    <animated.div
      style={cardStyle}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div>
        <CardContainer>
          <UpperCard>
            <div className="content">
              <LeftColumn>
                <div className="img-container">
                  <img src={source.iconSrc} alt="platform" />
                </div>
                <div className="info">
                  <BlackTitle result="true" needFormatting={needFormatting}>
                    {title}
                  </BlackTitle>
                  <CardText>{source.name}</CardText>
                </div>
              </LeftColumn>
              <DateDiv>
                {new Date(startsAt).toDateString()}, lasts {duration}
              </DateDiv>
              <ScheduleContainer up>
                <CardText>{formatNode}</CardText>
              </ScheduleContainer>
            </div>

            <div
              style={{
                display: 'flex',
                position: 'absolute',
                right: '0',
                top: '50%',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <ArrowIcon down />
            </div>
          </UpperCard>
          {isOpen && (
            <LowerCard>
              <div className="separator"></div>
              <div className="lc-container">
                <ScheduleContainer down>
                  <CardText down>{formatNode}</CardText>
                </ScheduleContainer>
                {financing.length > 0 && <BlackSubtitle main>Financial Options</BlackSubtitle>}
                {financing.length === 0 && <BlackSubtitle main>No financing options available</BlackSubtitle>}
                {trail.map((style, i) => {
                  const { option, description, icon } = financing[i]
                  // const { name, comment } = item
                  // TBD: ADD DESCRIPTION TO FINANCIAL OPTIONS

                  return (
                    <animated.div
                      className="finan-container"
                      key={i}
                      style={style}
                    >
                      <div>
                        {' '}
                        <div className="image">
                          <img src={icon} alt={option.toLowerCase()} />
                        </div>{' '}
                      </div>
                      <div className="finan-name">
                        <BlackSubtitle finan>{option} </BlackSubtitle>
                      </div>
                      <div className="comment-text">{description}</div>
                    </animated.div>
                  )
                })}
              </div>
            </LowerCard>
          )}
        </CardContainer>
      </div>
    </animated.div>
  )
})

export default ResultCard
