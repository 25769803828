import React from 'react'
import styled from 'styled-components/macro'
import loadingImage from '../../../../assets/loading/illustration.svg'
import loadingCurl from '../../../../assets/loading/loadingCurl.svg'
import { color, breakpoints, fonts } from '../../../../theme'
import { TextCenter } from '../../../ui-components'

const StyledSection = styled.section`
  display: flex;
  margin: 0 0 120px;
  min-height: 500px;
  justify-content: center;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1098px;
  margin: auto;
`
const Presentation = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  /* margin: 80px 65px auto;
  width: 50%;
  max-width: 521px; */

  @media ${breakpoints('md')} {
    text-align: unset;
    margin: 65px 142px 65px 35px;
  }
`

const StyledParagraph = styled.p`
  font-family: ${fonts('secondary')};
  margin: 8px 0 42px 0;
  padding: 0;
  padding-right: 0;
  font-size: 16px;
  font-weight: 400;
  color: ${color('purple')};
`

const StyledH1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  line-height: 76px;
  color: ${color('black')};
  font-family: ${fonts('primaryBlack')}, sans-serif;

  @media ${breakpoints('md')} {
    font-size: 3rem;
  }
  @media ${breakpoints('xl')} {
    font-size: 3.5rem;
  }
`

const HeroImage = styled.div`
  display: none;
  width: 378px;
  height: 377.4px;

  @media ${breakpoints('md')} {
    display: flex;
  }

  img {
    width: 378px;
    height: 377.4px;
  }
`
const LoadingCurl = styled.div`
  animation-name: play90;
  animation-duration: 1500ms;
  animation-timing-function: steps(90);
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  width: 195px;
  height: 42px;
  background-repeat: no-repeat;

  @keyframes play90 {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: -17550px 0px;
    }
  }
`

const Loading = () => {
  return (
    <StyledSection>
      <Container>
        <Presentation>
          <TextCenter>
            <StyledH1>
              We are preparing<br></br>your results
            </StyledH1>
          </TextCenter>

          <StyledParagraph>
            This may take a moment. Please wait!
          </StyledParagraph>
          <LoadingCurl
            style={{ backgroundImage: `url(${loadingCurl})` }}
          ></LoadingCurl>
        </Presentation>
        <HeroImage>
          <img src={loadingImage} alt="loading" />
        </HeroImage>
      </Container>
    </StyledSection>
  )
}

export default Loading
