import { createGlobalStyle } from 'styled-components';
import NunitoSansWoff from './NunitoSans.woff';
import NunitoSansWoff2 from './NunitoSans.woff2';
import PTSansWoff from './PTSansRegular.woff';
import PTSansWoff2 from './PTSansRegular.woff2';
import PoppinsWoff from './Poppins.woff';
import PoppinsWoff2 from './Poppins.woff2';
import PoppinsBoldWoff2 from './PoppinsBold.woff2';
import PoppinsBoldWoff from './PoppinsBold.woff';
import PoppinsBlackWoff2 from './PoppinsBlack.woff2';
import PoppinsBlackWoff from './PoppinsBlack.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'Nunito Sans';
        src: local('Nunito Sans'), local('NunitoSans'),
        url(${NunitoSansWoff2}) format('woff2'),
        url(${NunitoSansWoff}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'PT Sans';
        src: local('PT Sans'), local('PTSans'),
        url(${PTSansWoff2}) format('woff2'),
        url(${PTSansWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), local('Poppins'),
        url(${PoppinsWoff2}) format('woff2'),
        url(${PoppinsWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Poppins Bold';
        src: local('Poppins Bold'), local('PoppinsBold'),
        url(${PoppinsBoldWoff2}) format('woff2'),
        url(${PoppinsBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Poppins Black';
        src: local('Poppins Black'), local('PoppinsBlack'),
        url(${PoppinsBlackWoff2}) format('woff2'),
        url(${PoppinsBlackWoff}) format('woff');
        font-weight: 900;
        font-style: normal;
    }
`;